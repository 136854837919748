body {
  font-family: 'Glacial Indifference', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #fdf8ed;
}

.App {
  background-image: url('/src/assets/images/Jade-logo.jpeg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  min-height: 100vh;
  text-align: center;
}

