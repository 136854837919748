.checkout {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: rgba(255, 206, 52, 0.8);
  border-radius: 8px;
  border: 2px solid rgb(139, 36, 36);
  color: black;
  box-sizing: border-box;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: black;
}

.form-group input {
  width: calc(100% - 24px); /* Ensure inputs don't touch the edges */
  padding: 10px;
  margin: 0 auto; /* Center the inputs */
  border: 2px solid rgb(139, 36, 36);
  border-radius: 4px;
  background-color: white;
  box-sizing: border-box;
}

.place-order-button {
  display: block;
  width: calc(100% - 24px); /* Ensure button doesn't touch the edges */
  padding: 10px;
  background-color: #da565c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 20px auto 0; /* Add space above the button and center it */
  box-sizing: border-box;
}

.place-order-button:hover {
  background-color: #e2bac0;
}
