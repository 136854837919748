.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ab7a7a;
  border: 2px solid rgb(139, 36, 36);
  padding: 20px;
  color: whitesmoke;
  position: relative; /* Ensures the submenu overlay is positioned relative to the header */
}

.burger-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
  height: 20px;
  cursor: pointer;
}

.burger-bar {
  width: 100%;
  height: 3px;
  background-color: whitesmoke;
}

.submenu-overlay {
  position: fixed; /* Overlay takes the whole screen */
  top: 0;
  bottom: 0;
  right: 0; /* Position the overlay on the right */
  width: 250px; /* Adjust width as necessary */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1000; /* Ensures it's above other content */
}

.submenu {
  background-color: #a39485;
  box-shadow: -1px 0 5px rgba(0, 0, 0, 0.2); /* Adjust shadow to appear on the left side */
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.submenu-item {
  padding: 10px;
  transition: background-color 0.3s, border-radius 0.3s;
  color: whitesmoke;
  text-decoration: none; /* Remove underline from links */
}

.submenu-item:hover {
  cursor: pointer;
  background-color: #b36154;
  border-radius: 8px;
}
