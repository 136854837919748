.cart-icon {
    position: fixed;
    top: 70px;
    right: 10px;
    background-color: #da565c;
    color: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.2s, background-color 0.3s; /* Smooth transition for scaling */
  }

  .cart-icon:hover {
    background-color:  #e2bac0;
  }
  
  .cart-icon .fa-shopping-cart {
    font-size: 24px;
  }
  
  .cart-count {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: white;
    color: #da565c;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 12px;
    font-weight: bold;
  }
  
  /* Shake animation keyframes */
  @keyframes shake {
    0%, 100% {
      transform: translateX(0);
    }
    25%, 75% {
      transform: translateX(-5px);
    }
    50% {
      transform: translateX(5px);
    }
  }
  
  .animate {
    animation: shake 0.5s;
  }
  