.shopping-cart {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: rgba(255, 206, 52, 0.8);
  border-radius: 8px;
  border: 2px solid rgb(139, 36, 36); /* Added border to match the product cards */
  color: black; /* Updated text color */
}

.cart-item {
  display: flex;
  margin-bottom: 20px;
  border-bottom: 2px solid rgb(139, 36, 36); /* Updated border color */
  padding-bottom: 10px;
}

.cart-item-image {
  width: 150px; /* Fixed width */
  height: auto; /* Height adjusts automatically to maintain aspect ratio */
  margin-right: 20px;
  object-fit: cover; /* Ensures the image covers the area without distortion */
  border-radius: 4px; /* Optional: Adds rounded corners to the images */
  border: 1px solid rgb(139, 36, 36); /* Optional: Adds a border around the images to match the theme */
}

.cart-item-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cart-item-details p {
  margin: 0;
}

.quantity-control {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.quantity-control button {
  background-color: #da565c; /* Updated button color */
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin: 0 5px;
  border-radius: 5px; /* Added border-radius */
}

.quantity-control button:hover {
  background-color: #e2bac0; /* Updated hover color */
}

.custom-messages {
  margin: 10px 0;
}

.custom-message-item {
  margin-bottom: 10px;
}

.custom-message-item label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.custom-message-item textarea {
  width: calc(100% - 24px);
  padding: 10px;
  border: 2px solid rgb(139, 36, 36);
  border-radius: 4px;
  background-color: white;
  box-sizing: border-box;
  resize: vertical;
}

.cart-total {
  text-align: right;
  margin-top: 20px;
  font-weight: bold; /* Added font-weight for emphasis */
}

.checkout-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #da565c; /* Updated button color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.checkout-button:hover {
  background-color: #e2bac0; /* Updated hover color */
}


