.section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.product-card {
  width: 300px;
  border: 2px solid rgb(139, 36, 36);
  border-radius: 8px;
  overflow: hidden;
  background-color: rgb(185, 179, 148);
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure the footer stays at the bottom */
}

.product-image {
  width: 100%;
  height: 350px; /* Fixed height to ensure consistent card sizes */
  object-fit: cover; /* Ensures the image covers the area without distorting */
  object-position: center; /* Center the image */
}

.product-info {
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
}

.product-info p {
  margin: 0;
}

.product-info select {
  margin: 10px auto;
  border: 2px solid rgb(139, 36, 36);
}

.custom-message {
  width: calc(100% - 20px);
  padding: 10px;
  border: 2px solid rgb(139, 36, 36);
  border-radius: 4px;
  background-color: white;
  box-sizing: border-box;
  margin: 10px 0;
  resize: vertical;
  min-height: 60px;
  max-height: 150px;
  overflow-y: auto;
}

.add-message-button {
  padding: 5px 10px;
  background-color: #8b2424;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.add-message-button:hover {
  
  background-color: #e2bac0;
}

.product-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: #f7f7f7;
  border-top: 1px solid rgb(139, 36, 36);
}

.product-price {
  font-weight: bold;
}

.add-to-cart-button {
  padding: 5px 10px;
  background-color: #da565c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-to-cart-button:hover {
  background-color: #e2bac0;
}