.order-complete {
  text-align: center;
  padding: 20px;
  background-color: rgba(249, 249, 249, 0.9); /* Light background to match the site's theme */
  border: 2px solid #8b2424; /* Border color to match the site's theme */
  border-radius: 8px; /* Smooth corners to match the card style */
  max-width: 600px; /* Restrict max width to maintain a clean layout */
  margin: 0 auto; /* Center the component horizontally */
}

.order-complete h2 {
  font-size: 2.5em; /* Increase font size for the heading */
  margin-bottom: 20px;
  color: #8b2424; /* Theme color for the heading */
}

.order-complete p {
  font-size: 1.2em;
  margin: 10px 0;
  color: #333; /* Dark text color for readability */
}

.order-number-container {
  margin-top: 20px;
  padding: 10px;
  background-color: rgba(255, 206, 52, 1); /* Updated background color */
  border: 2px solid #8b2424; /* Border color to match the theme */
  border-radius: 5px; /* Smooth corners */
  display: inline-block;
  position: relative;
  overflow: hidden; /* Hide the overflow for the shine effect */
}

.order-number-container p {
  font-size: 1.5em;
  margin: 0;
  color: #8b2424; /* Theme color */
}

.order-number {
  font-size: 2em; /* Larger font size for the order number */
  color: #da565c; /* Accent color to highlight the order number */
  position: relative;
  z-index: 1;
}

/* Shine effect */
.order-number-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.5), transparent);
  z-index: 0;
  transform: skewX(-20deg);
  animation: shine 1.5s infinite; /* Run the animation continuously */
}

/* Keyframes for the shine animation */
@keyframes shine {
  0% {
    left: -100%;
  }
  50% {
    left: 50%;
  }
  100% {
    left: 100%;
  }
}

