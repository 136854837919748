.section {
  background-color: rgba(255, 206, 52, 0.8);
  border: 2px solid rgb(139, 36, 36);
  color: black; /* Light text color for contrast */
  padding: 20px;
  border-radius: 8px; /* Optional: rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Shadow to create the raised effect */
  margin: 20px; /* Optional: adds some space around the container */
  max-width: 80%; /* Limiting width for better aesthetics */
  margin-left: auto;
  margin-right: auto; /* Centering the container */
  font-size: larger;
  position: relative; /* Make the section relative for positioning */
  padding-top: 80px; /* Adjust this value based on the height of your logo */
}

  .image-container {
    display: flex;
    justify-content: center;
    margin: 30px 0 20px 0;
}

.image-container img {
  border: 2px solid rgb(139, 36, 36);
    width: 300px;
    height: auto;
    border-radius: 20px;
    box-shadow: 10px 5px 5px rgba(139, 36, 36, 0.5), -.5em 0 .4em rgba(235, 167, 150, 0.75) ; /* Optional: add shadow to the image */
}

.paragraph-container {
  border: 2px solid rgb(139, 36, 36);
  background: rgba(235, 167, 150, 0.8); /* Semi-transparent background */
  padding: 20px;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Shadow to create depth */
  margin-top: 20px;
  width: 60%;

}



  
  .windOverlay {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
  }
  
  .windOverlay::after {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(241, 241, 241, 0.75) 50%, rgba(255, 255, 255, 0) 100%);
    animation: windEffect 3s linear forwards;
    border-radius: 10px;
  }
  
  @keyframes windEffect {
    0% {
      left: -100%;
    }
    100% {
      left: 100%;
    }
  }