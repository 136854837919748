.contact-section {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background: rgba(255, 206, 52, 0.8);
    border: 2px solid 	rgb(139,36,36);
    border-radius: 8px;
    box-sizing: border-box; /* Ensure padding is included in the width */
  }
  
  .contact-heading {
    text-align: center;
    margin-bottom: 20px;
    color: #333; /* Added color for better visibility */
  }
  
  .contact-form .form-group {
    margin-bottom: 15px;
  }
  
  .contact-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333; /* Added color for better visibility */
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Ensure padding is included in the width */
    border: 2px solid 	rgb(139,36,36);
  }
  
  .contact-form .input-error {
    border-color: red;
  }
  
  .contact-form .error-message {
    color: red;
    font-size: 0.9em;
    margin-top: 5px;
  }
  
  .contact-form .submit-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #da565c;
    border: 2px solid 	rgb(139,36,36);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px; /* Ensure the button doesn't touch the textarea above */
  }
  
  .contact-form .submit-button:hover {
    background-color: #e2bac0;
  }
  
  .success-message {
    text-align: center;
    color: green;
    margin-top: 20px;
  }
  
  .footer {
    text-align: center;
    margin-top: 30px;
  }
  
  .footer .social-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 10px;
  }
  
  .footer .social-icons a {
    color: #333;
    font-size: 1.5em;
    transition: color 0.3s;
    
  }
  
  .footer .social-icons a:hover {
    color: #007bff;
  }